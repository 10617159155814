import React, { Component } from "react";
import Paper from '@material-ui/core/Paper';
import Marquee from 'react-double-marquee';


import "./ticker.scss";

export default class ticker extends Component {
  state = { data: "", wow: [] };

  componentWillMount = () => {
    let socket = new WebSocket("wss://stream.binance.com/stream");

    socket.onopen = function (e) {
      socket.send(
        JSON.stringify({
          id: 1,
          method: "SUBSCRIBE",
          params: ["!miniTicker@arr@3000ms"],
        })
      );
    };

    socket.onmessage = (event) => {
      // console.log()

      // this.setState({data:JSON.stringify(daa)})
      let daa = JSON.parse(event.data).data;

      try {
        var btc = daa.filter((element) => element.s == "BTCUSDT");

        this.setState({
          bitcoin: parseFloat(btc[0].c).toFixed(2),
          btcper: parseFloat(((btc[0].c - btc[0].o) / btc[0].c) * 100).toFixed(2),
        });
      } catch {}

      try {
        var eth = daa.filter((element) => element.s == "ETHUSDT");
        this.setState({ eth: parseFloat(eth[0].c).toFixed(2),
            ethper: parseFloat(((eth[0].c - eth[0].o) / eth[0].c) * 100).toFixed(2),
         });
      } catch {}

      try {
        var bnb = daa.filter((element) => element.s == "BNBUSDT");
        this.setState({ bnb: parseFloat(bnb[0].c).toFixed(2),
            bnbper: parseFloat(((bnb[0].c - bnb[0].o) / bnb[0].c) * 100).toFixed(2), });
      } catch {}

      try {
        var eos = daa.filter((element) => element.s == "EOSUSDT");
        this.setState({ eos: parseFloat(eos[0].c).toFixed(2),
            eosper: parseFloat(((eos[0].c - eos[0].o) / eos[0].c) * 100).toFixed(2), });
      } catch {}

      try {
        var xrp = daa.filter((element) => element.s == "XRPUSDT");

        this.setState({ xrp: parseFloat(xrp[0].c).toFixed(2),
            xrpper: parseFloat(((xrp[0].c - xrp[0].o) / xrp[0].c) * 100).toFixed(2) });
      } catch {}

      try {
        var bch = daa.filter((element) => element.s == "BCHUSDT");
        this.setState({ bch: parseFloat(bch[0].c).toFixed(2),
            bchper: parseFloat(((bch[0].c - bch[0].o) / bch[0].c) * 100).toFixed(2), });
      } catch {}

      try {
        var ltc = daa.filter((element) => element.s == "LTCUSDT");
        this.setState({ ltc: parseFloat(ltc[0].c).toFixed(2),
            ltcper: parseFloat(((ltc[0].c - ltc[0].o) / ltc[0].c) * 100).toFixed(2), });
      } catch {}
    };
  };

  render() {
    return (<>
       {this.state.bitcoin&&<Paper elevation={3}>          

      <div className="fl">
       

        <div>
           
        {this.state.bitcoin&& <div className={this.state.btcper > 0 ? "green" : "red"}>
          <img className='ico' src="https://cryptologos.cc/logos/bitcoin-btc-logo.svg?v=003" width='20px' alt=""/>
            <b> BTC </b> 
            <br />${this.state.bitcoin}
            <span className="small">
              <br />({this.state.btcper > 0 ? "+" : null}
              {this.state.btcper}%)
            </span>
          </div>}

         
          {this.state.eth&&<div className={this.state.ethper > 0 ? "green" : "red"}>
              <img src="https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=003" width='16px' alt=""/>
            <b> ETH </b>
            <br />${this.state.eth}
            <span className="small">
              <br />({this.state.ethper > 0 ? "+" : null}
              {this.state.ethper}%)
            </span>
          </div>}

        
          {this.state.bnb&&<div className={this.state.bnbper > 0 ? "green" : "red"}>
                            <img src="https://cryptologos.cc/logos/binance-coin-bnb-logo.svg?v=003" width='20px' alt=""/>

            <b> BNB </b>
            <br />${this.state.bnb}
            <span className="small">
              <br />({this.state.bnbper > 0 ? "+" : null}
              {this.state.bnbper}%)
            </span>
          </div>}

          {this.state.eos&&<div className={this.state.eosper > 0 ? "green" : "red"}>
                            <img src="https://cryptologos.cc/logos/eos-eos-logo.svg?v=003" width='16px' alt=""/>

            <b> EOS </b>

            <br />
            ${this.state.eos}
            <span className="small">
              <br />({this.state.eosper > 0 ? "+" : null}
              {this.state.eosper}%)
            </span>
          </div>}

          {this.state.xrp&&<div className={this.state.xrpper > 0 ? "green" : "red"}>
                            <img src="https://cryptologos.cc/logos/xrp-xrp-logo.svg?v=003" width='20px' alt=""/>

            <b> XRP </b>
            <br />${this.state.xrp}
            <span className="small">
              <br />({this.state.xrpper > 0 ? "+" : null}
              {this.state.xrpper}%)
            </span>
          </div>}
          {/* <div> <b>BCH</b> ${this.state.bch}</div> */}

          {this.state.ltc&&<div className={this.state.ltcper > 0 ? "green" : "red"}>
                            <img src="https://cryptologos.cc/logos/litecoin-ltc-logo.svg?v=003" width='20px' alt=""/>

            <b> LTC </b>
            <br />${this.state.ltc}
            <span className="small">
              <br />({this.state.ltcper > 0 ? "+" : null}
              {this.state.ltcper}%)
            </span>
          </div>}



        </div>
      </div>     

      </Paper>}</>
    );
  }
}
