import Ticker from "./ticker";

import YouTube from "react-youtube";

import React, { Component, useEffect, useState } from "react";

import "./App.scss";
import "./assets/WEBFONT/stylesheet.css";
import "reactjs-popup/dist/index.css";

// ~~~~~~~~~~~~~~~~~~~~~~~
// MATERIAL

import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Popup from "reactjs-popup";

// const ExpansionPanelDetails = withStyles(() => ({
//   root: {
//     width: "100%",
//   },
//   expanded: {
//     height: "110px"
//   }
// }))(MuiExpansionPanelDetails);

const Vid = (props) => (
  <ExpansionPanel>
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography>{props.title}</Typography>
    </ExpansionPanelSummary>

    <ExpansionPanelDetails style={{ flexDirection: "column" }}>
      <YouTube
        videoId={props.videoId}
        opts={{
          height: "390",
          width: "100%",
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
          },
        }}
      />
    </ExpansionPanelDetails>
  </ExpansionPanel>
);

function ss(text) {
  return text
    .split("")
    .map((char) => char + "\u0336")
    .join("");
}

function round(num, decimalPlaces = 0) {
  num = Math.round(num + "e" + decimalPlaces);
  return Number(num + "e" + -decimalPlaces);
}

export default class App extends Component {
  state = {
    copy: 0,
    btc: "",
    eth: "",
    ltc: "",
    etc: "",
    inr: "",
    usdt1: "",
    usdt2: "",
    btcbep: "",
    memobep: "",
    btctab: 0,
  };

  componentDidMount = () => {
    // axios('https://spreadsheets.google.com/values=json').then(x=>{
    // console.log(x.data)
    // })
    let params = new URLSearchParams(window.location.search);
    let foo = params.get("off");

    this.setState({ off: foo <= 30 ? foo : null });

    fetch(
      "https://sheets.googleapis.com/v4/spreadsheets/1FZ6dA3WNvCEjUS6OUMwdKx4M0ixuchVAj_gr_SyjAg0/values/A1:F25/?key=AIzaSyCyywfGd9USBf8DhaB6jrn_COzH2kqau_I&majorDimension=COLUMNS"
    )
      .then((x) => x.json())
      .then((x) => {
        console.log(x.values[5][1]);
        this.setState({
          // m1:x.values,
          // m2:x.values,

          // btc:x.values,
          //  btcp1:x.values,
          //  btcp2: x.values,

          //  eth:x.values,
          //  ethp1:x.values,
          //  ethp2:x.values,

          //  ltc:x.values,
          //  ltcp1:x.values,
          //  ltcp2:x.values,

          //  etc:x.values,
          //  etcp1:x.values,
          //  etcp2:x.values,

          //  usdt1:x.values.split(", ")[0],
          //  usdt2:x.values.split(", ")[1],

          //  inr:x.values,
          //  inrp1:x.values,
          //  inrp2:x.values,

          //  btcbep:x.values,
          //  memobep:x.values,

          // sheet:x.values,

          m1: x.values[2][0],
          m2: x.values[3][0],

          btc: x.values[1][1],
          btcp1: x.values[2][1],
          btcp2: x.values[3][1],

          eth: x.values[1][2],
          ethp1: x.values[2][2],
          ethp2: x.values[3][2],

          ltc: x.values[1][3],
          ltcp1: x.values[2][3],
          ltcp2: x.values[3][3],

          etc: x.values,
          etcp1: x.values[2][4],
          etcp2: x.values[3][4],

          usdt1: x.values[1][4].split(", ")[0],
          usdt2: x.values[1][4].split(", ")[1],

          inr: x.values[1][5],
          inrp1: x.values[2][5],
          inrp2: x.values[3][5],

          btcbep: x.values[1][7],
          memobep: x.values[1][8],

          discount: x.values[5][1],

          off: x.values[5][1].split(",").includes(foo) ? foo : null,
        });

        console.log(this.state.discount);
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="main">
        {/*      
      <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <Centered>
        <Button onClick={() => alert("click")}>Hello</Button>
        </Centered>
      </BaseProvider>
    </StyletronProvider> */}
        <div className="container">
          <Ticker />

          <div className="title">
            <span className="titlemark">
              ✅ {"Elite Crypto Premium Joining Instructions".toUpperCase()} 👑
              {/* ✅ {"Elite Crypto Premium + Elite Crypto Tool  Joining Instructions".toUpperCase()} 👑 */}
            </span>
          </div>

          <br />
          <img
            src="https://i2.wp.com/www.cryptokosh.com/wp-content/uploads/2018/10/cryptokosh-banner-join.png?w=1309&ssl=1"
            width="80%"
            alt=""
          />
          {/* <img src="https://i.imgur.com/NkIhxyK.png" width='80%' alt=""/> */}

          <p className="warning">
            ⚠️Trading Crypto is risky. One should know what they are doing
            before investing.
          </p>

          <div className="instr">
            {/* <img src={require('./assets/viptool.jpg')} width='100%' alt=""/> */}

            <h2 style={{ paddingLeft: 50, textAlign: "left" }}>
              📌How to Join?
            </h2>
            <p className="off">
              {" "}
              <mark>
                Must Note: Send us Fee + transaction fee Excluding ( Means you
                pay your transaction fee)
              </mark>
            </p>

            <div className="card">
              <div className="number">1</div>
              <p>
                Download and Install{" "}
                <a href="https://telegram.org/">Telegram Messanger</a>
              </p>
            </div>

            <div className="card">
              <div className="number">2</div>
              <p>
                Choose any one method to pay.{" "}
                <b>
                  Fee For {this.state.m2} or {this.state.m1}
                </b>
                {/* <p>+ <img src={require('./assets/free.jpg')} width="50px" style={{"marginTop":20}}/> </p> */}
                <span style={{ marginTop: 10 }} className="blink_me">
                  <br />
                  <img
                    style={{ verticalAlign: "middle" }}
                    src={require("./assets/free.jpg")}
                    width="50px"
                  />
                  <span style={{ color: "red", fontSize: 15 }}>
                    Futures Trading Course Included!!{" "}
                  </span>
                </span>
                <span style={{ fontSize: 15 }}>
                  <a
                    href="https://elite.cryptokosh.com/course/futures-course"
                    target="_blank"
                  >
                    Full Course Details
                  </a>
                </span>
              </p>

              {this.state.off && (
                <p
                  style={{
                    fontSize: 17,
                    color: "white",
                    fontWeight: "bold",
                    background: "red",
                    padding: 10,
                    display: "inline-block",
                  }}
                >
                  Discount {this.state.off}% off !!
                </p>
              )}

              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Bitcoin</Typography>
                </ExpansionPanelSummary>

                {this.state.off ? (
                  <ExpansionPanelDetails
                    style={{ color: "#29AB61", fontSize: 17 }}
                  >
                    <p>
                      {this.state.m2}: <b className="of">{this.state.btcp2}</b>{" "}
                      <b>
                        {round(
                          parseFloat(this.state.btcp2) -
                            (parseFloat(this.state.btcp2) *
                              parseFloat(this.state.off)) /
                              100,
                          5
                        )}{" "}
                        BTC
                      </b>
                      <br />
                      {this.state.m1}: <b className="of">{this.state.btcp1}</b>{" "}
                      <b>
                        {round(
                          parseFloat(this.state.btcp1) -
                            (parseFloat(this.state.btcp1) *
                              parseFloat(this.state.off)) /
                              100,
                          5
                        )}{" "}
                        BTC
                      </b>
                      <p style={{ color: "black", background: "#ffffb3" }}>
                        Use BEP2 network For Less Fees
                      </p>
                    </p>
                  </ExpansionPanelDetails>
                ) : (
                  <ExpansionPanelDetails
                    style={{ color: "#29AB61", fontSize: 17 }}
                  >
                    <p>
                      {this.state.m2}: <b> {this.state.btcp2}</b>
                      <br />
                      {this.state.m1}: <b>{this.state.btcp1}</b>
                      <p style={{ color: "black", background: "#ffffb3" }}>
                        Use BEP2 network For Less Fees
                      </p>
                    </p>
                  </ExpansionPanelDetails>
                )}

                <ExpansionPanelDetails>
                  <Paper style={{ width: "100%" }}>
                    <Tabs
                      value={this.state.btctab}
                      onChange={(x, n) => {
                        this.setState({ btctab: n });
                      }}
                      indicatorColor="primary"
                      centered
                    >
                      <Tab label="BTC Address" />
                      <Tab label={"BTC BEP2"} />
                    </Tabs>
                  </Paper>
                </ExpansionPanelDetails>

                {this.state.btctab == 0 ? (
                  <ExpansionPanelDetails>
                    <TextField
                      inputProps={{
                        style: {
                          color: this.state.copy == 1 ? "green" : "black",
                        },
                      }}
                      color="primary"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      value={this.state.btc}
                      id="standard-basic"
                      label="Bitcoin Address"
                    />
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        navigator.clipboard.writeText(this.state.btc);
                        this.setState({ copy: 1 });
                      }}
                    >
                      {this.state.copy == 1 ? "Copied!!" : "Copy"}
                    </Button>
                  </ExpansionPanelDetails>
                ) : (
                  <>
                    <ExpansionPanelDetails>
                      <p style={{ fontSize: 13, color: "red" }}>
                        Make Sure you sending using BEP2 Network. Both Address &
                        Memo are required
                      </p>
                    </ExpansionPanelDetails>

                    <ExpansionPanelDetails>
                      <TextField
                        inputProps={{
                          style: {
                            color: this.state.copy == 10 ? "green" : "black",
                          },
                        }}
                        color="primary"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        value={this.state.btcbep}
                        id="standard-basic"
                        label="Bitcoin BEP2 Address"
                      />
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          navigator.clipboard.writeText(this.state.btcbep);
                          this.setState({ copy: 10 });
                        }}
                      >
                        {this.state.copy == 10 ? "Copied!!" : "Copy"}
                      </Button>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                      <TextField
                        inputProps={{
                          style: {
                            color: this.state.copy == 20 ? "green" : "black",
                          },
                        }}
                        color="primary"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        value={this.state.memobep}
                        id="standard-basic"
                        label="Bitcoin BEP2 MEMO"
                      />
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          navigator.clipboard.writeText(this.state.memobep);
                          this.setState({ copy: 20 });
                        }}
                      >
                        {this.state.copy == 20 ? "Copied!!" : "Copy"}
                      </Button>
                    </ExpansionPanelDetails>
                  </>
                )}
              </ExpansionPanel>

              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Ethereum</Typography>
                </ExpansionPanelSummary>

                {this.state.off ? (
                  <ExpansionPanelDetails
                    style={{ color: "#29AB61", fontSize: 17 }}
                  >
                    <p>
                      {this.state.m2}: <b className="of">{this.state.ethp2}</b>{" "}
                      <b>
                        {round(
                          parseFloat(this.state.ethp2) -
                            (parseFloat(this.state.ethp2) *
                              parseFloat(this.state.off)) /
                              100,
                          3
                        )}{" "}
                        Eth
                      </b>
                      <br />
                      {this.state.m1}: <b className="of">{this.state.ethp1}</b>{" "}
                      <b>
                        {round(
                          parseFloat(this.state.ethp1) -
                            (parseFloat(this.state.ethp1) *
                              parseFloat(this.state.off)) /
                              100,
                          3
                        )}{" "}
                        Eth
                      </b>
                    </p>
                  </ExpansionPanelDetails>
                ) : (
                  <ExpansionPanelDetails
                    style={{ color: "#29AB61", fontSize: 17 }}
                  >
                    <p>
                      {this.state.m2}: <b> {this.state.ethp2}</b>
                      <br />
                      {this.state.m1}: <b>{this.state.ethp1}</b>
                    </p>
                  </ExpansionPanelDetails>
                )}

                <ExpansionPanelDetails>
                  <Typography></Typography>

                  <TextField
                    inputProps={{
                      style: {
                        color: this.state.copy == 2 ? "green" : "black",
                      },
                    }}
                    color="primary"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={this.state.eth}
                    id="standard-basic"
                    label="Ethereum Address"
                  />
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      navigator.clipboard.writeText(this.state.eth);
                      this.setState({ copy: 2 });
                    }}
                  >
                    {this.state.copy == 2 ? "Copied!!" : "Copy"}
                  </Button>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Litecoin</Typography>
                </ExpansionPanelSummary>
                {this.state.off ? (
                  <ExpansionPanelDetails
                    style={{ color: "#29AB61", fontSize: 17 }}
                  >
                    <p>
                      {this.state.m2}: <b className="of">{this.state.ltcp2}</b>{" "}
                      <b>
                        {round(
                          parseFloat(this.state.ltcp2) -
                            (parseFloat(this.state.ltcp2) *
                              parseFloat(this.state.off)) /
                              100,
                          3
                        )}{" "}
                        LTC
                      </b>
                      <br />
                      {this.state.m1}: <b className="of">{this.state.ltcp1}</b>{" "}
                      <b>
                        {round(
                          parseFloat(this.state.ltcp1) -
                            (parseFloat(this.state.ltcp1) *
                              parseFloat(this.state.off)) /
                              100,
                          3
                        )}{" "}
                        LTC
                      </b>
                    </p>
                  </ExpansionPanelDetails>
                ) : (
                  <ExpansionPanelDetails
                    style={{ color: "#29AB61", fontSize: 17 }}
                  >
                    <p>
                      {this.state.m2}: <b> {this.state.ltcp2}</b>
                      <br />
                      {this.state.m1}: <b>{this.state.ltcp1}</b>
                    </p>
                  </ExpansionPanelDetails>
                )}
                <ExpansionPanelDetails>
                  <Typography></Typography>
                  <TextField
                    inputProps={{
                      style: {
                        color: this.state.copy == 4 ? "green" : "black",
                      },
                    }}
                    color="primary"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={this.state.ltc}
                    id="standard-basic"
                    label="litecoin Address"
                  />
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      navigator.clipboard.writeText(this.state.ltc);
                      this.setState({ copy: 4 });
                    }}
                  >
                    {this.state.copy == 4 ? "Copied!!" : "Copy"}
                  </Button>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>USDT</Typography>
                </ExpansionPanelSummary>
                {this.state.off ? (
                  <ExpansionPanelDetails
                    style={{ color: "#29AB61", fontSize: 17 }}
                  >
                    <p>
                      {this.state.m2}: <b className="of">{this.state.etcp2}</b>{" "}
                      <b>
                        $
                        {round(
                          parseFloat(String(this.state.etcp2).substring(1)) -
                            (parseFloat(String(this.state.etcp2).substring(1)) *
                              parseFloat(this.state.off)) /
                              100
                        )}
                      </b>
                      <br />
                      {this.state.m1}: <b className="of">{this.state.etcp1}</b>{" "}
                      <b>
                        $
                        {round(
                          parseFloat(String(this.state.etcp1).substring(1)) -
                            (parseFloat(String(this.state.etcp1).substring(1)) *
                              parseFloat(this.state.off)) /
                              100
                        )}
                      </b>
                    </p>
                  </ExpansionPanelDetails>
                ) : (
                  <ExpansionPanelDetails
                    style={{ color: "#29AB61", fontSize: 17 }}
                  >
                    <p>
                      {this.state.m2}: <b> {this.state.etcp2}</b>
                      <br />
                      {this.state.m1}: <b>{this.state.etcp1}</b>
                    </p>
                  </ExpansionPanelDetails>
                )}
                <ExpansionPanelDetails>
                  <Typography></Typography>

                  <TextField
                    inputProps={{
                      style: {
                        color: this.state.copy == 3 ? "green" : "black",
                      },
                    }}
                    color="primary"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={this.state.usdt1}
                    id="standard-basic"
                    label="ERC20 Address"
                  />
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      navigator.clipboard.writeText(this.state.usdt1);
                      this.setState({ copy: 3 });
                    }}
                  >
                    {this.state.copy == 3 ? "Copied!!" : "Copy"}
                  </Button>
                </ExpansionPanelDetails>
                <ExpansionPanelDetails>OR</ExpansionPanelDetails>
                <ExpansionPanelDetails>
                  <TextField
                    inputProps={{
                      style: {
                        color: this.state.copy == 4 ? "green" : "black",
                      },
                    }}
                    color="primary"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={this.state.usdt2}
                    id="standard-basic"
                    label="TRC20 Address"
                  />
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      navigator.clipboard.writeText(this.state.usdt2);
                      this.setState({ copy: 4 });
                    }}
                  >
                    {this.state.copy == 4 ? "Copied!!" : "Copy"}
                  </Button>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>INR</Typography>
                </ExpansionPanelSummary>

                <INRPannel
                  price1={this.state.inrp1}
                  price2={this.state.inrp2}
                  month1={this.state.m1}
                  month2={this.state.m2}
                  off={this.state.off}
                  upiId={this.state.inr}
                />
              </ExpansionPanel>
            </div>

            <div className="card">
              <div className="number">3</div>
              <p>
                Send payment screenshot to telegram support{" "}
                <a href="https://telegram.me/elitegroupsupport">
                  telegram.me/elitegroupsupport
                </a>
                <br />
                OR @elitegroupsupport
                <ul
                  style={{
                    fontSize: 14,
                    background: "#cef5f9c2",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <li>Send ONLY AFTER PAYMENT CONFIRMS</li>
                  <li>Send what amount and which coin you have sent.</li>
                  <li>
                    {" "}
                    <mark>Don't Send Secret Chat, Send Normal Chat</mark>
                  </li>
                </ul>
              </p>
            </div>

            <div className="card">
              <div className="number">4</div>
              <p>
                You will be added to our paid group as soon as we receive the
                payment. 😄
              </p>
            </div>

            <div className="card">
              <div className="number">5</div>
              <p>
                [Optional] If you haven't Join the Binance Join now 👉🏼{" "}
                <a href="http://bit.ly/techllog-binance-join">
                  Join binance Now!
                </a>{" "}
              </p>
              <p> </p>

              <Vid
                title="🟢 Watch Binance Full Tutorial Trading"
                videoId="qFJCu0EKxo8"
              />
              <Vid
                title="🟢 Watch WazirX Full Tutorial Trading"
                videoId="DlYDzKiDJN4"
              />
              <Vid
                title="🟢 Buy/Sell Crypto using Binance P2P"
                videoId="ZgoSX69bvPo"
              />
            </div>

            <div className="card">
              <div className="number">6</div>
              <p>
                After joining Our Telegram Channel <b>Pin it to Top</b>
              </p>
              <img
                src="https://i1.wp.com/www.cryptokosh.com/wp-content/uploads/2019/02/pin.png?zoom=2&ssl=1"
                width="100%"
                alt=""
              />
            </div>
          </div>

          {/* 

    
<div className="title">
            <span className="titlemark">
              INSTAGRAM
            </span>
            <br/>

            <Button variant="outlined" size="medium" color="primary"
            onClick={()=>{window.location.replace('https://www.instagram.com/elitecryptotraders/')}}>
            Follow on instagram
        </Button>



          </div> */}

          {/*          
<div className="insta">
  
<InstaGrid  account="elitecryptotraders" numberOfMediaElements={9} />

</div> */}

          <div className="title">
            <span className="titlemark">FAQ</span>
            <br />
          </div>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <b>Of which exchange, signals will be served?</b>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                We will be serving you signal for multiple exchanges. It doesn't
                matter because many time coin is listed on the various exchange.
                Choose a good exchange like binance which hold various coins.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <b>Can I see for test signals first?</b>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                You can Join our Free Signal Channel. We post few signals in
                free channel, But premium is best.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <b>Is fees refundable?</b>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Unfortunately fees is non refundable for the elite group
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {" "}
                <b>You don't reply me when i text you?</b>{" "}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                This happens manytimes due to our busy schedule. But Signal
                details are all given in the call. We will text you as soon as
                we can.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {" "}
                <b>I've paid the fee but no one replying on telegram!</b>{" "}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Maybe you sent us secret chat, please send normal chal. Or
                contact us on support@techllog.com
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <br />
          <br />
        </div>
      </div>
    );
  }
}

String.prototype.strike = function () {
  return '<div class="strike">' + this + "</div>";
};

const INRPannel = ({ upiId, month1, price1, month2, price2, off }) => {
  const [copied, setCopy] = useState(false);

  useEffect(() => {}, []);

  const calculatePrice2 = off
    ? round(parseFloat(price2) - (parseFloat(price2) * parseFloat(off)) / 100)
    : price2;

  const calculatePrice1 = off
    ? round(parseFloat(price1) - (parseFloat(price1) * parseFloat(off)) / 100)
    : price1;

  return (
    <>
      {off ? (
        <ExpansionPanelDetails style={{ color: "#29AB61", fontSize: 17 }}>
          <p>
            {month2}: <b className="of">{price2}</b> <b> ₹ {calculatePrice2}</b>
            <br />
            {month1}: <b className="of">{price1}</b> <b> ₹ {calculatePrice1}</b>
          </p>
        </ExpansionPanelDetails>
      ) : (
        <ExpansionPanelDetails style={{ color: "#29AB61", fontSize: 17 }}>
          <p>
            {month2}: <b>₹{price2}</b>
            <br />
            {month1}: <b>₹{price1}</b>
          </p>
        </ExpansionPanelDetails>
      )}
      <ExpansionPanelDetails>
        <Typography></Typography>
        <TextField
          inputProps={{
            style: {
              color: copied ? "green" : "black",
            },
          }}
          color="primary"
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          value={upiId}
          id="standard-basic"
          label="UPI Address"
        />
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            navigator.clipboard.writeText(upiId);
            setCopy(true);
          }}
        >
          {copied ? "Copied!!" : "Copy"}
        </Button>
      </ExpansionPanelDetails>
      <ExpansionPanelDetails>
        <Popup
          trigger={
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(upiId);
              }}
            >
              Pay using UPI App
            </Button>
          }
          modal
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: 350,
              margin: "0 auto",
              gap: 10,
              minHeight: 200,
            }}
          >
            <p>Pay using UPI</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                gap: 10,
              }}
            >
              <a
                style={{ textDecoration: "none" }}
                href={`upi://pay?pn=Elite%20Group%20&pa=${upiId}&cu=INR&am=${calculatePrice2}`}
              >
                <Button size="small" variant="contained">
                  {month2}
                </Button>
              </a>
              <a
                style={{ textDecoration: "none" }}
                href={`upi://pay?pn=Elite%20Group%20&pa=${upiId}&cu=INR&am=${calculatePrice1}`}
              >
                <Button size="small" variant="contained">
                  {month1}
                </Button>
              </a>
            </div>
          </div>
        </Popup>
      </ExpansionPanelDetails>
    </>
  );
};
